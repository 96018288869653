import axios from 'axios';

// Create an Axios instance
const api = axios.create({
  baseURL: 'http://api.ojaak.com/auction/api/',
  // baseURL: 'http://127.0.0.1:8000/ojaak/api/',
  withCredentials: true,
  headers: {
    'Content-Type': 'application/json',
  },
});

// Function to get the CSRF token from cookies
const getCsrfToken = () => {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; csrftoken=`);
  if (parts.length === 2) return parts.pop().split(';').shift();
};

// Function to fetch and set the CSRF token
export const fetchCsrfToken = async () => {
  const response = await api.get('/csrf/');
  if (response && response.data) {
    document.cookie = `csrftoken=${response.data.csrfToken}; path=/;`;
  }
};

// Add a request interceptor to attach the token
api.interceptors.request.use(
  config => {
    const token = localStorage.getItem('access_token');
    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`;
    }
    const csrfToken = getCsrfToken();
    if (csrfToken) {
      config.headers['X-CSRFToken'] = csrfToken;
    }
    return config;
  },
  error => Promise.reject(error)
);

// Response interceptor to handle token refresh
api.interceptors.response.use(
  response => response,
  async error => {
    const originalRequest = error.config;
    // If it's a 401 Unauthorized error and not a retry request, try refreshing the token
    if (error.response && error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;
      try {
        const refreshToken = localStorage.getItem('refresh_token');
        if (!refreshToken) {
          // If no refresh token, log out the user
          throw new Error('No refresh token available');
        }
        // Attempt to refresh the token
        const { data } = await api.post('token/refresh/', { refresh: refreshToken });
        localStorage.setItem('access_token', data.access);
        localStorage.setItem('refresh_token', data.refresh);
        originalRequest.headers['Authorization'] = `Bearer ${data.access}`;
        return api(originalRequest);
      } catch (err) {
        // If token refresh fails, log the user out and redirect to login
        handleLogout();
        return Promise.reject(err);
      }
    }
    return Promise.reject(error);
  }
);

// Function to handle logout
export const handleLogout = () => {
  // Remove tokens on logout
  localStorage.removeItem('access_token');
  localStorage.removeItem('refresh_token');
  // You can also redirect to the login page or show a logout message here
};

// Function to refresh the token at regular intervals
export const setupTokenRefresh = () => {
  setInterval(async () => {
    const refreshToken = localStorage.getItem('refresh_token');
    if (refreshToken) {
      try {
        const { data } = await api.post('token/refresh/', { refresh: refreshToken });
        localStorage.setItem('access_token', data.access);
        localStorage.setItem('refresh_token', data.refresh);
      } catch (err) {
        // Token refresh failed, log the user out
        handleLogout();
      }
    }
  }, 30 * 60 * 1000); // Refresh token every 28 minutes
};

// Inactivity logout setup
let lastActivityTime = Date.now();

const resetLogoutTimer = () => {
  lastActivityTime = Date.now();
};

const checkInactivity = () => {
  if (Date.now() - lastActivityTime >= 30 * 60 * 1000) { // 30 minutes
    handleLogout();
  }
};

const setupInactivityTimer = () => {
  const events = ['mousemove', 'keypress', 'click', 'touchstart', 'scroll'];
  events.forEach(event => {
    window.addEventListener(event, resetLogoutTimer);
  });

  document.addEventListener('visibilitychange', () => {
    if (document.visibilityState === 'visible') {
      resetLogoutTimer(); // Reset timer when the user comes back to the tab
    }
  });

  resetLogoutTimer(); // Initialize the timer
  setInterval(checkInactivity, 60000); // Check every minute
};

// Start the inactivity timer
setupInactivityTimer();

export default api;
