import React from 'react'
import { Link } from 'react-router-dom'

const Footer = () => {
    return (
        <div>
            <footer>
                <div className="footer-top">
                    <div className="container">
                        <div className="row gy-5">
                            <div className="col-lg-3 col-md-6">
                                <div className="footer-item">
                                    <Link to="/home"><img className='footer-logo-size' src="/assets/images/bg/ojaak_logo.svg" /></Link>
                                    <p>Explore on the world's best & largest marketplace with our beautiful products. We want to be a part of your smile, success and future growth.</p>
                                    <form>
                                        <div className="input-with-btn d-flex jusify-content-start align-items-strech">
                                            <input type="text" placeholder="Enter your email" />
                                            <button type="submit"><img alt="" src="/assets/images/icons/send-icon.svg" /></button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-6 d-flex justify-content-lg-center">
                                <div className="footer-item">
                                    <h5>Navigation</h5>
                                    <ul className="footer-list">
                                        <li><Link to="/blog">All Product</Link></li>
                                        <li><Link to="/blog">How It Works</Link></li>
                                        <li><Link to="/blog">My Account</Link></li>
                                        <li><Link to="/blog">About Company</Link></li>
                                        <li><Link to="/blog">Our News Feed</Link></li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-6 d-flex justify-content-lg-center">
                                <div className="footer-item">
                                    <h5>Help & FAQs</h5>
                                    <ul className="footer-list">
                                        <li><Link to="/blog">Help Center</Link></li>
                                        <li><Link to="/blog">Customer FAQs</Link></li>
                                        <li><Link to="/home2">Terms and Conditions</Link></li>
                                        <li><Link to="/blog">Security Information</Link></li>
                                        <li><Link to="/home3">Privacy Policy</Link></li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-6">
                                {/* <div className="footer-item">
                                    <h5>Latest Feed</h5>
                                    <ul className="recent-feed-list">
                                        <li className="single-feed">
                                            <div className="feed-img">
                                                <Link to="/blog-details"><img alt="" src="/assets/images/blog/recent-feed1.png"
                                                /></Link>
                                            </div>
                                            <div className="feed-content">
                                                <span>January 31, 2022</span>
                                                <h6><Link to="/blog-details">Grant Distributions Conti nu to Incr Ease.</Link>
                                                </h6>
                                            </div>
                                        </li>
                                        <li className="single-feed">
                                            <div className="feed-img">
                                                <Link to="/blog-details"><img alt="" src="/assets/images/blog/recent-feed2.png"
                                                /></Link>
                                            </div>
                                            <div className="feed-content">
                                                <span>February 21, 2022</span>
                                                <h6><Link to="/blog-details">Seminar for Children to Learn About.</Link></h6>
                                            </div>
                                        </li>
                                        <li className="single-feed">
                                            <div className="feed-img">
                                                <Link to="/blog-details"><img alt="" src="/assets/images/blog/recent-feed3.png"
                                                /></Link>
                                            </div>
                                            <div className="feed-content">
                                                <span>March 22, 2022</span>
                                                <h6><Link to="/blog-details">Education and teacher for all African
                                                    Children.</Link></h6>
                                            </div>
                                        </li>
                                    </ul>
                                </div> */}

                            </div>
                        </div>
                    </div>
                </div>
                <div className="footer-bottom">
                    <div className="container">
                        <div className="row d-flex align-items-center g-4">
                            <div className="col-lg-6 d-flex justify-content-lg-start justify-content-center">
                                <p>Copyright 2024 <Link to="#">Ojaak</Link> | Design By <Link
                                    to="/"
                                    className="egns-lab">Ojaak</Link></p>
                            </div>
                            <div
                                className="col-lg-6 d-flex justify-content-lg-end justify-content-center align-items-center flex-sm-nowrap flex-wrap">
                                <p className="d-sm-flex d-none">We Accepts:</p>
                                <ul className="footer-logo-list">
                                    <li><Link to="#"><img alt="" src="/assets/images/bg/footer-pay1.png" /></Link></li>
                                    <li><Link to="#"><img alt="" src="/assets/images/bg/footer-pay2.png" /></Link></li>
                                    <li><Link to="#"><img alt="" src="/assets/images/bg/footer-pay3.png" /></Link></li>
                                    <li><Link to="#"><img alt="" src="/assets/images/bg/footer-pay4.png" /></Link></li>
                                    <li><Link to="#"><img alt="" src="/assets/images/bg/footer-pay5.png" /></Link></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
        </div>
    )
}

export default Footer