// import React, { useState, useEffect } from "react";
// import api from "../../api";
// import { useTable } from 'react-table';
// import DatePicker from 'react-datepicker';
// import 'react-datepicker/dist/react-datepicker.css';
// import Modal from 'react-modal';
// import { jwtDecode } from 'jwt-decode';
// import { useNavigate } from 'react-router-dom';

// const PaymentForm = () => {
//     const [name, setName] = useState('');
//     const [amount, setAmount] = useState('');
//     const [error, setError] = useState(null);
//     const [data, setData] = useState([]);
//     const [startDate, setStartDate] = useState(null);
//     const [endDate, setEndDate] = useState(null);
//     const [isModalOpen, setIsModalOpen] = useState(false);
//     const navigate = useNavigate();

//     const handlePayment = async () => {
//         const token = localStorage.getItem('access_token');
//         setError(null);

//         // Validate amount
//         if (amount.trim() === '' || isNaN(amount) || amount <= 0 || amount > 10000) {
//             setError('Please enter a valid amount up to 10,000.');
//             return;
//         }

//         try {
//             const response = await api.post('payment/', { name, amount }, {
//                 headers: { 'Authorization': `Bearer ${token}`, 'X-CSRFToken': getCookie('csrftoken') }
//             });

//             const data = response.data;
//             const options = {
//                 key: data.razorpay_key_id,
//                 amount: data.amount * 100,
//                 currency: 'INR',
//                 name: data.name,
//                 order_id: data.order_id,
//                 description: "Add money to your OJAAK wallet",
//                 image: "https://example.com/your_logo.jpg",
//                 prefill: {
//                     name: data.name,
//                     email: '',
//                     contact: '',
//                 },
//                 theme: {
//                     color: "#F37254"
//                 },
//                 handler: function (response) {
//                     verifyPayment(response);
//                 }
//             };
//             const rzp = new window.Razorpay(options);
//             rzp.open();
//         } catch (error) {
//             console.error('Error during payment:', error);
//             setError(error.response?.data?.error || 'Something went wrong');
//         }
//     };

//     const verifyPayment = async (response) => {
//         const token = localStorage.getItem('access_token');
//         setError(null);
//         try {
//             const verifyResponse = await api.post('payment/verify/', response, {
//                 headers: { 'Authorization': `Bearer ${token}`, 'X-CSRFToken': getCookie('csrftoken') }
//             });

//             if (verifyResponse.data.status === 'payment verified') {
//                 window.location.reload();
//             } else {
//                 throw new Error(verifyResponse.data.error || 'Payment verification failed.');
//             }
//         } catch (error) {
//             console.error('Error during payment verification:', error);
//             setError(error.response?.data?.error || 'Payment verification failed.');
//         }
//     };

//     function getCookie(name) {
//         let cookieValue = null;
//         if (document.cookie && document.cookie !== '') {
//             const cookies = document.cookie.split(';');
//             for (let i = 0; i < cookies.length; i++) {
//                 const cookie = cookies[i].trim();
//                 if (cookie.substring(0, name.length + 1) === (name + '=')) {
//                     cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
//                     break;
//                 }
//             }
//         }
//         return cookieValue;
//     }

//     useEffect(() => {
//         const fetchData = async () => {
//             const token = localStorage.getItem('access_token');
//             if (!token) {
//                 setError('User not authenticated');
//                 return;
//             }

//             try {
//                 const decodedToken = jwtDecode(token);
//                 const user_id = decodedToken.user_id;

//                 // Fetch payments for the current user
//                 // const response = await fetch(`http://127.0.0.1:8000/auction/exportpayments/${user_id}/`, {
//                 const response = await fetch(`http://api.ojaak.com/auction/exportpayments/${user_id}/`, {
//                     headers: { 'Authorization': `Bearer ${token}`, 'X-CSRFToken': getCookie('csrftoken') }
//                 });
//                 const result = await response.json();
//                 setData(Array.isArray(result) ? result : []);
//             } catch (error) {
//                 console.error('Error decoding token or fetching data:', error);
//                 setError('Failed to fetch data');
//             }
//         };

//         fetchData();
//     }, []);

//     const columns = React.useMemo(
//         () => [
//             {
//                 Header: 'ITEM',
//                 accessor: 'item',
//             },
//             {
//                 Header: 'Order ID',
//                 accessor: 'order_id',
//             },
//             {
//                 Header: 'Payment ID',
//                 accessor: 'payment_id',
//             },
//             {
//                 Header: 'Payment METHOD',
//                 accessor: 'method',
//             },

//             {
//                 Header: 'Amount',
//                 accessor: 'amount',
//                 Cell: ({ value, row }) => (
//                     <span
//                         style={{
//                             color: row.original.transaction_type === 'addition' ? 'green' : 'red'
//                         }}
//                     >
//                         {row.original.transaction_type === 'addition' ? '+' : '-'}₹{Math.abs(value)}
//                         {row.original.transaction_reason === 'bid' ? ' (Bidding)' : row.original.transaction_reason === 'register' ? ' (Register)' : ''}
//                     </span>
//                 ),
//             }, ,
//             {
//                 Header: 'Date',
//                 accessor: 'created_at',
//                 Cell: ({ value }) => new Date(value).toLocaleDateString(),
//                 Header: ({ column }) => (
//                     <div onClick={() => setIsModalOpen(true)}>
//                         Date
//                     </div>
//                 )
//             },
//             {
//                 Header: 'Status',
//                 accessor: 'paid',
//                 Cell: ({ value }) => (value === 'Yes' ? 'Success' : 'Failed'),
//             },
//         ],
//         []
//     );

//     const filteredData = data.filter(row => {
//         const date = new Date(row.created_at);
//         if (startDate && date < startDate) return false;
//         if (endDate && date > endDate) return false;
//         return true;
//     })
//         .sort((a, b) => new Date(b.created_at) - new Date(a.created_at)) // Sort by date descending


//     const {
//         getTableProps,
//         getTableBodyProps,
//         headerGroups,
//         rows,
//         prepareRow,
//     } = useTable({ columns, data });

//     const handleRowClick = (item) => {
//         const numericPart = item.split('-')[1];
//         navigate(`/auction-details/${numericPart}`);
//     };

//     return (
//         <div className="payment-form-container">
//             <div className="payment-form">
//                 <h1>OJAAK MONEY</h1>
//                 <p>Add money to your OJAAK wallet to place your bid</p>
//                 <form
//                     className="form"
//                     onSubmit={(e) => {
//                         e.preventDefault();
//                         handlePayment();
//                     }}
//                 >
//                     <input
//                         type="number"
//                         value={amount}
//                         onChange={(e) => setAmount(e.target.value)}
//                         placeholder="Enter amount"
//                         className="input"
//                         max="10000"
//                     />
//                     {error && <div className="error-message">{error}</div>}
//                     <button type="submit" className="pay-button">
//                         PAY NOW
//                     </button>
//                 </form>
//                 <div className="container info-message">
//                     <i className="info-icon">
//                         <img src="assets/images/bg/note_eye.svg" alt="info icon" />
//                     </i>
//                     10,000 is the maximum limit. Above entered amount will be securely
//                     added to your Ojaak wallet.
//                 </div>
//             </div>

//             <div className="container my-5">
//                 <div className="details-tab-btn">
//                     <h1 className="text-center">Transaction History</h1>
//                 </div>
//                 <div className="table-responsive">
//                     <table className="table text-center align-middle">
//                         <thead>
//                             <tr>
//                                 <th>ORDER ID</th>
//                                 <th>
//                                     DATE <span>⇅</span>
//                                 </th>
//                                 <th>PAYMENT METHOD</th>
//                                 <th>STATUS</th>
//                                 <th>ITEM</th>
//                                 <th>AMOUNT</th>
//                                 <th>TRANSACTION</th>
//                             </tr>
//                         </thead>
//                         <tbody>
//                             {filteredData.length > 0 ? (
//                                 filteredData.map((transaction, index) => (
//                                     <tr
//                                         key={`${transaction.item}-${index}`}
//                                         className="details-tab"
//                                         onClick={() => handleRowClick(transaction.item)}
//                                     >
//                                         <td>{transaction.order_id}</td>
//                                         <td>
//                                             {new Date(transaction.created_at).toLocaleDateString()}
//                                         </td>
//                                         <td>{transaction.method}</td>
//                                         <td>
//                                             <span
//                                                 className={
//                                                     transaction.paid === "Yes"
//                                                         ? "status-completed"
//                                                         : "status-pending"
//                                                 }
//                                             >
//                                                 {transaction.paid === "Yes" ? "Completed" : "Pending"}
//                                             </span>
//                                         </td>
//                                         <td>{transaction.item}</td>
//                                         <td>
//                                             <span
//                                                 className={
//                                                     transaction.transaction_type === "addition"
//                                                         ? "amount-positive"
//                                                         : "amount-negative"
//                                                 }
//                                             >
//                                                 {transaction.transaction_type === "addition"
//                                                     ? "+"
//                                                     : "-"}
//                                                 ₹{Math.abs(transaction.amount)}
//                                             </span>
//                                         </td>
//                                         {/* <td>{transaction.transaction_reason}</td> */}
//                                         <td>
//                                             <span>
//                                                 {transaction.transaction_type === "bid"
//                                                     ? ""
//                                                     : transaction.transaction_reason === "register"
//                                                         ? ""
//                                                         : ""}
//                                                 ₹{transaction.transaction_reason}
//                                             </span>
//                                         </td>
//                                     </tr>
//                                 ))
//                             ) : (
//                                 <tr>
//                                     <td colSpan="7">No transactions found.</td>
//                                 </tr>
//                             )}
//                         </tbody>
//                     </table>
//                 </div>
//             </div>

//             <Modal
//                 isOpen={isModalOpen}
//                 onRequestClose={() => setIsModalOpen(false)}
//                 className="modal-content"
//             >
//                 <div className="modal-header">
//                     <h2>Date Filter</h2>
//                 </div>
//                 <div className="date-picker-container">
//                     <DatePicker
//                         selected={startDate}
//                         onChange={(date) => setStartDate(date)}
//                         selectsStart
//                         startDate={startDate}
//                         endDate={endDate}
//                         placeholderText="Start Date"
//                         className="date-picker"
//                     />
//                     <DatePicker
//                         selected={endDate}
//                         onChange={(date) => setEndDate(date)}
//                         selectsEnd
//                         startDate={startDate}
//                         endDate={endDate}
//                         placeholderText="End Date"
//                         className="date-picker"
//                     />
//                 </div>
//                 <div className="modal-footer">
//                     <button onClick={() => setIsModalOpen(false)}>Apply Filter</button>
//                 </div>
//             </Modal>
//         </div>
//     );
// };

// export default PaymentForm;

import React, { useState } from "react";
import axios from "axios";
import api from '../../api'
function PaymentForm() {
    const [amount, setAmount] = useState("");
    const [paymentUrl, setPaymentUrl] = useState(null);

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const res = await api.post("initiate-payment/", { amount: parseInt(amount) });
            const paymentData = res.data;
            console.log("check data", paymentData);
            
            if (paymentData.success && paymentData.data.instrumentResponse.redirectInfo.url) {
                setPaymentUrl(paymentData.data.instrumentResponse.redirectInfo.url);
                window.open(paymentData.data.instrumentResponse.redirectInfo.url, "_blank", "width=500,height=700"); 
                
            } else {
                alert("Failed to initiate payment");
            }
        } catch (err) {
            console.error(err);
            alert("An error occurred. Please try again.");
        }
    };

    const openPaymentPopup = (paymentUrl) => {
        if (paymentUrl) {
            console.log("check url", paymentUrl);
            
            const newWindow = window.open(paymentUrl, "_blank", "width=500,height=700");
            if (!newWindow) {
                alert("Please allow pop-ups for this website");
            }
        } else {
            alert("Payment URL is not available");
        }
    };

    return (
        <div>
            <h1>PhonePe Payment</h1>
            <form onSubmit={handleSubmit}>
                <label>
                    Amount:
                    <input
                        type="number"
                        value={amount}
                        onChange={(e) => setAmount(e.target.value)}
                    />
                </label>
                <button type="submit">Initiate Payment</button>
            </form>

            {paymentUrl && (
                <div>
                    <h2>Payment Ready</h2>
                    <button onClick={openPaymentPopup}>Pay with PhonePe</button>
                </div>
            )}
        </div>
    );
}

export default PaymentForm;

